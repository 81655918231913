/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
/* .navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  border-bottom: 3px solid #f2f2f2;
} */

.navbar h1 {
  /* color: #757575; */
  color: whitesmoke;
  text-align: center;
}
h2{
  /* color: #757575; */
  color: whitesmoke;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #757575;
}
.content {
  max-width: 90%;
  margin: 40px auto;
  padding: 20px;
}

.gridButton{
  background-color: #F7F7F7;
  color: black;
  padding: 1%;
}

.gridButton:hover{
  background-color: #eceaead7;
}

.modal{
  display: hidden;
  position: fixed; 
  z-index: 1; 
  padding-top: 100px;
  left: 0; 
  top: 0; 
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content{
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%; 
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop; 
  animation-duration: 0.4s;
}

@-webkit-keyframes animatetop{
  from {top:-300px; opacity: 0}
  to {top:0; opacity:1}
}

@keyframes animatetop{
  from {top:-300px; opacity: 0}
  to {top:0; opacity:1}
}

.modal-header{
  padding: 2px 16px;
  background-color: #3484F0; 
  color: whitesmoke;
}

.modal-body{
  padding: 2px 16px; 
}

.gridButtonClaimed{
  color: black;
  padding: 1%;
  background: linear-gradient(to top left,
                            rgba(0,0,0,0) 0%,
                            rgba(0,0,0,0) calc(50% - 0.8px),
                            rgba(0,0,0,1) 50%,
                            rgba(0,0,0,0) calc(50% + 0.8px),
                            rgba(0,0,0,0) 100%);
                            background-color: #F7F7F7;
}

.gridButtonClaimed:hover{
  background-color: #eceaead7;
}

#claimPrompt{
  display: none;
}

#donorMessage{
  color: whitesmoke;
}

#ayat{
  font-weight: bold;
  font-style: italic; 
}

#refreshMessage{
  color: whitesmoke;
}

#refreshMessage:hover{
  color: blue;
}

/* #title{
  text-align: center; 
} */

.h1Block{
  border-bottom: 3px solid #f2f2f2;
}

.h1Block h1{
  padding: 20px;
  color: whitesmoke;
}

#imgLogo{
  padding-top: 20px;
}

#container1{
  max-width: 100%;
}